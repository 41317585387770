import {
  useRef, useLayoutEffect, useState, useCallback, useEffect,
} from 'react'

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

const useResizeObserver = () => {
  const [observerEntry, setObserverEntry] = useState({})
  const [node, setNode] = useState(null)
  const observer = useRef(null)

  const disconnect = useCallback(() => observer.current?.disconnect(), [])

  const observe = useCallback(() => {
    observer.current = new ResizeObserver(([entry]) => setObserverEntry(entry))
    if (node) observer.current.observe(node)
  }, [node])

  useIsomorphicLayoutEffect(() => {
    observe()
    return () => disconnect()
  }, [disconnect, observe])

  return [setNode, observerEntry]
}

export default useResizeObserver
