const cardEventFragment = `
  headline
  location
  course
  startDate
  endDate
  link
  ctaLabel
  slug
  eventCategory {
    name
  }
  image {
    url
  }
  logo {
    url
  }
  heroImage {
    url
  }
  sys {
    id
  }
`
export default cardEventFragment
