import cardEventFragment from '@components/card-event/card-event.fragment'
import { slideFragment } from '@schema/slide'
import storyCardFragment from '@schema/story-card'

export const storySpotlightFragment = `
__typename
... on Slide {
  ${slideFragment}
}
... on Story {
  ${storyCardFragment}
}
... on Event {
  ${cardEventFragment}
}
`
