import PropTypes from 'prop-types'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import StorySpotlight from './story-spotlight'

export default function StorySpotlightWithData(props) {
  const {
    query, graphqlQuery, parentCollection, variables,
  } = props
  const QUERY = graphqlQuery
  const queryVariables = { ...query, ...variables }

  return (
    <Query query={QUERY} variables={queryVariables}>
      {({
        loading, error, data,
      }) => {
        if (error) {
          return <Error error={error} displayErrorType="component" />
        }
        if (loading) return <LinearProgress color="secondary" />
        const response = data && data[parentCollection] && data[parentCollection].items[0]

        return (
          <StorySpotlight {...props} {...response} />
        )
      }}
    </Query>
  )
}

StorySpotlightWithData.propTypes = {
  query: PropTypes.object,
  graphqlQuery: PropTypes.object.isRequired,
  variables: PropTypes.object,
  parentCollection: PropTypes.string.isRequired,
}
