import ButtonCta from '@components/atoms/button-cta'
import Image from '@components/atoms/image'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import PropTypes from 'prop-types'
import Link from '@components/link/index'
import StoryPreviewCard from './story-preview-card'
import useStyles from './story-spotlight.styles'
import primaryCtaText from './utils'
import { DateTime } from 'luxon'
import { gtmEvent } from '@lib/gtm-utils'

const StorySpotlight = (props) => {
  const {
    featuredStoriesCollection,
    featuredStoriesHeadline,
    featuredStoriesTitle,
    featuredStoriesCtaLink,
    featuredStoriesCtaText,
    featuredStoriesSummaryExtended,
    featuredStoriesImage,
    padding,
  } = props
  const styles = useStyles()

  if (featuredStoriesCollection.total === 0) return <></>

  const hasCta = !!featuredStoriesCtaText && !!featuredStoriesCtaLink
  const featuredStory = featuredStoriesCollection.items[0]
  const featuredStoryImage = featuredStoriesImage || featuredStory.image
  const linkProps = featuredStoriesCollection.items[0]?.reference || featuredStoriesCollection.items[0]
    || { externalLink: featuredStoriesCtaLink }

  return (
    <Box bgcolor="common.white" pt={padding?.pt || [5, 5]} pb={padding?.pb || [4, 4, 8]}>
      <Container className={styles.root}>
        {featuredStoriesCollection.items.length === 1 ? (
          // Only 1 item is featured treatment
          <Grid className={styles.gridContainer} container direction="column-reverse">
            <Grid item xs={12} md={6} lg={5}
              onClick={() => gtmEvent({
                event: 'click-featured-story',
                attributes: {
                  title: featuredStory?.headline,
                  category: featuredStory?.cat?.category,
                  story_author: featuredStory?.author,
                  publish_date: DateTime.fromISO(featuredStory?.sys?.publishedAt).toFormat('yyyy-LL-dd'),
                },
              })}>
              <Box pb={4} pr={[0, 0, 4]}>
                <Box>
                  <Box display={{ xs: 'none', md: 'block' }} mb="3.125rem">
                    <Typography
                      variant="overline"
                      className={styles.overline}
                    >
                      {featuredStoriesHeadline}
                    </Typography>
                  </Box>
                  <Link {...linkProps}>
                    <Box mb=".875rem" mt={[2, 2, 0]} color="secondary.light">
                      <Typography variant="h3">
                        {featuredStoriesTitle || featuredStory.headline}
                      </Typography>
                    </Box>
                    <Typography variant="body1" className={styles.subtitle}>
                      {featuredStoriesSummaryExtended || featuredStory.summary}
                    </Typography>
                  </Link>
                </Box>
                <Box>
                  <ButtonCta
                    variant="contained"
                    color="primary"
                    reference={linkProps}
                    dataAttrs={{ 'aria-label': `Read more about ${featuredStory?.headline || featuredStoriesTitle}` }}
                  >{primaryCtaText(featuredStory.__typename)}
                  </ButtonCta>
                  {hasCta && (
                    <ButtonCta
                      href={featuredStoriesCtaLink}
                      variant="text"
                      arrow
                    >{featuredStoriesCtaText}
                    </ButtonCta>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} lg={7}>
              <Box display={{ xs: 'block', md: 'none' }}>
                <Typography
                  variant="overline"
                  className={styles.overline}
                  style={{ textAlign: 'center', marginBottom: '1.75rem' }}
                >
                  {featuredStoriesHeadline}
                </Typography>
              </Box>
              <Box className={styles.imageContainer} mb={[4, 4, 0]}>
                {featuredStoryImage && (
                  <Image
                    className={styles.spotlightImage}
                    src={`${featuredStoryImage?.url}?h=900&w=1600&fit=fill&fl=progressive&q=80`}
                    alt={featuredStoryImage?.description || `${featuredStory?.__typename || 'Story'}  image`}
                  />
                )
                }
              </Box>
            </Grid>
          </Grid>
        ) : (
          // More than 1 item featured
          <Box>
            <Box className={styles.headerLine}>
              <Typography variant="h4" className={styles.headline}>{featuredStoriesHeadline}</Typography>
              <Hidden smDown>
                {hasCta && (
                  <ButtonCta className={styles.headerCta} href={featuredStoriesCtaLink} variant="text" color="primary" arrow>{featuredStoriesCtaText}</ButtonCta>
                )}
              </Hidden>
            </Box>

            <Grid container className={styles.multiContainer}>
              <Grid item xs={12} md={6}>
                <Box className={styles.leftGrid}>
                  <Box className={styles.imageContainerMulti}>
                    {featuredStory?.image && (
                      <Link {...linkProps}>
                        <Image
                          className={styles.image}
                          src={`${featuredStory.image?.url}?h=450&w=800&fit=fill&fl=progressive&q=80`}
                          alt={featuredStory.image.description || `${featuredStory.__typename}`}
                        />
                      </Link>
                    )
                    }
                  </Box>
                  <Box className={styles.leftNonImageContent}>
                    <Link {...linkProps}>
                      <Box mt={1} mb={3}>
                        <Typography variant="h4">
                          {featuredStoriesHeadline || featuredStory.headline}
                        </Typography>
                      </Box>
                    </Link>
                    <Box className={styles.mediumCta}>
                      <ButtonCta
                        variant="contained"
                        color="primary"
                        size="small"
                        reference={linkProps}
                      >{featuredStoriesCtaText || primaryCtaText(featuredStory.__typename)}
                      </ButtonCta>
                    </Box>
                    <Hidden mdUp>
                      <Box className={styles.divider} />
                    </Hidden>
                  </Box>
                </Box>
              </Grid>
              <Grid container item xs={12} md={6}>
                <Box className={styles.rightGrid}>
                  {featuredStoriesCollection.items.slice(1, 5).map(item => (
                    <StoryPreviewCard key={item.sys.id} item={item} showImage />
                  ))}
                </Box>
              </Grid>
            </Grid>
            {hasCta && (
              <Hidden mdUp>
                <Box className={styles.divider} my={3} />
                <Box className={styles.mCta} mt={2}>
                  <ButtonCta href={featuredStoriesCtaLink} variant="text" color="primary" size="small" arrow>{featuredStoriesCtaText}</ButtonCta>
                </Box>
              </Hidden>
            )}
          </Box>
        )
        }
      </Container>
    </Box>
  )
}

StorySpotlight.propTypes = {
  featuredStoriesCollection: (PropTypes.shape({
    total: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.shape({
      __typename: PropTypes.oneOf(['Story', 'Event', 'Slide']),
      summary: PropTypes.string,
      headline: PropTypes.string,
      subtitle: PropTypes.string,
      slug: PropTypes.string,
      ctaText: PropTypes.string,
      viewAllText: PropTypes.string,
      externalLink: PropTypes.string,
      reference: PropTypes.object,
      image: PropTypes.shape({
        description: PropTypes.string,
        url: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
      }),
    })),
  })),
  featuredStoriesHeadline: PropTypes.string,
  featuredStoriesTitle: PropTypes.string,
  featuredStoriesCtaText: PropTypes.string,
  featuredStoriesCtaLink: PropTypes.string,
  featuredStoriesSummaryExtended: PropTypes.string,
  featuredStoriesImage: PropTypes.shape({
    description: PropTypes.string,
    url: PropTypes.string,
  }),
  padding: PropTypes.shape({
    pt: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    pb: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  }),
}

StorySpotlight.defaultProps = {
  featuredStoriesHeadline: 'Latest News in Golf',
  featuredStoriesCtaText: 'View all stories',
  featuredStoriesCtaLink: 'stories/all',
}

export default StorySpotlight
