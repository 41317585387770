import PropTypes from 'prop-types'
import ButtonCta from '@components/atoms/button-cta'
import Image from '@components/atoms/image'
import { Typography, Box } from '@material-ui/core'
import Link from '@components/link/index'
import useStyles from './story-spotlight.styles'
import primaryCtaText from './utils'

const StoryPreviewCard = (props) => {
  const {
    item,
    showImage,
  } = props
  const styles = useStyles()
  const linkProps = item.reference || item

  return (
    <Box className={styles.previewContainer}>
      {showImage && item.image && (
        <Link {...linkProps}>
          <Box className={styles.previewimageContainer}>
            <Image
              className={styles.image}
              src={`${item.image.url}?h=450&w=800&fit=fill&fl=progressive&q=80`}
              alt={item.image.description || `${item.__typename}`}
            />
          </Box>
        </Link>
      )}
      <Box className={styles.previewNoImageContainer}>
        <Link {...linkProps}>
          <Typography className={styles.previewHeadline} variant="h5">{item.headline}</Typography>
        </Link>
        <ButtonCta className={styles.previewCta} variant="text" color="primary" size="small" reference={linkProps} arrow>{primaryCtaText(item.__typename)}</ButtonCta>
      </Box>
    </Box>
  )
}

StoryPreviewCard.propTypes = {
  item: PropTypes.shape({
    __typename: PropTypes.oneOf(['Story', 'Event', 'Slide']),
    summary: PropTypes.string,
    headline: PropTypes.string,
    subtitle: PropTypes.string,
    slug: PropTypes.string,
    ctaText: PropTypes.string,
    viewAllText: PropTypes.string,
    externalLink: PropTypes.string,
    reference: PropTypes.object,
    image: PropTypes.shape({
      description: PropTypes.string,
      url: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }),
  showImage: PropTypes.bool,
}

StoryPreviewCard.defaultProps = {}

export default StoryPreviewCard
