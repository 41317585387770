import storyCardFragment from './story-card'

export const slideFragment = `
sys {
  id
}
headline
summary
ctaText
series
image {
  url
}
imageMobile {
  url
}
contentfulMetadata {
  tags {
    name
  }
}
externalLink
reference {
  __typename
  ... on Page {
    slug
  }
  ... on Story {
    ${storyCardFragment}
  }
  ... on Event {
    slug
  }
  ... on Tour {
    slug
  }
  ... on PromotionalPage {
    slug
  }
}
secondaryCtaText
secondaryExternalLink
secondaryReference {
  __typename
  ... on Page {
    slug
  }
  ... on Story {
    ${storyCardFragment}
  }
  ... on Event {
    slug
  }
  ... on Tour {
    slug
  }
  ... on PromotionalPage {
    slug
  }
}
`
